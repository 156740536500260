.ganttTable {
    display: table;
    width: '100%'!important;
    border-bottom: #e6e4e4 1px solid;
    border-top: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
  }
  
  .ganttTable_Header {
    display: table-row;
    list-style: none;
    border-bottom: #e6e4e4 1px solid;
    border-top: #e6e4e4 1px solid;
    border-left: #e6e4e4 1px solid;
    text-align: center;
  }
  
  .ganttTable_HeaderSeparator {
    border-right: 1px solid rgb(196, 196, 196);
    opacity: 1;
    margin-left: -2px;
  }
  
  .ganttTable_HeaderItem {
    display: table-cell;
    vertical-align: -webkit-baseline-middle;
    vertical-align: middle;
    font-size: 10;
  }