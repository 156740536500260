@media screen and (max-width: 600px) {
    .MuiDrawer-paperAnchorRight  {
        width: '90%' !important;
    }
}

@media screen and (min-width: 600px) {    
    .MuiDrawer-paperAnchorRight {
        width: 400px !important;
    }
}
