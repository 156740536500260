.record-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #f44336;
    margin-right: 10px;
    transform: scale(0);
    transition: transform 0.3s ease;
  }
  
  .record-circle.recording {
    animation: pulse 1s infinite;
  }
  
  .recording {
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  