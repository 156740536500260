.image-gallery {
  width: 100%;
  /* height: auto; */
}

.image-gallery-slide img {
  max-width: 100%;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  height: auto;

  max-height: 100vh;
}
