.custom-scroll {
}
.custom-scroll::-webkit-scrollbar {
  height: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: white;
}

.custom-scroll::-webkit-scrollbar-thumb {
  width: 10px;
  background: rgb(195, 193, 193);
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.event-info:hover {
  opacity: 0.6;
  cursor: pointer;
}

.event-success:hover {
  opacity: 0.6;
  cursor: pointer;
}

.item-pointer {
  cursor: pointer;
}
